import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Section from "../view/component/section";
import Avatar from "../view/component/avatar";
import CachedImg from "../view/component/cachedImg";
import Button from "../view/component/button";
import Loader from "../view/component/loader";

import constant from "../config/constant";
import endpoint from "../config/endpoint";
import { SessionContext } from "../util/context";
import { getDate, getDateISO, getLangCountryURL, strings } from "../util/formatter";
import { request } from "../util/http";

const Contributor = () =>
{
    const { prefs } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [contributorData, setContributorData] = useState({});
    const [productList, setProductList] = useState([]);

    const loadMore = () =>
    {
        if (shouldLoadMore)
        {
            setPageNumber(pageNumber + 1);
        }
    };

    const BasicContentList = (title, list, isLink = false, id = "", path = "") =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <div className="flex flex-col gap-grid-xs">
                    {list?.length > 0 && list?.map((item, index) => (
                        isLink
                            ? <Link key={index} to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${path}/${item?.[id]}`} className="underline underline-offset-2 text-primary-regular">
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name_ar : list[index]?.name_en}
                            </Link>
                            : <p key={index}>
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name_ar : list[index]?.name_en}
                            </p>
                    ))}
                </div>
            </div>
        );
    };

    const BasicContent = (title, item) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <p>
                    {item}
                </p>
            </div>
        );
    };

    const ContentWithBG = (title, data, isList = true) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {isList
                    ? data?.length > 0 && data?.map((item, index) => (
                            <p key={index} className="px-grid-md py-grid-xxs bg-primary-light text-secondary-darkest text-center rounded-xl">
                                {prefs?.lang === constant.lang.values[0] ? data[index]?.name_ar : data[index]?.name_en}
                            </p>
                        ))
                    : <p className="px-grid-md py-grid-xxs bg-primary-light text-secondary-darkest text-center rounded-xl">
                            {data}
                        </p>
                    }
                </div>
            </div>
        );
    };

    const fetchContributorData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.contributor.data}?contributor_id=6741a36f07188307835bf7f9`);
            setContributorData(res?.info);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    const fetchProductList = async (isLoadMore = false) =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.list}?page=${pageNumber}&timestamp=${getDateISO()}&sort_type=produced_at&contributor_id=6741a36f07188307835bf7f9`);
            setProductList(isLoadMore ? productList?.concat(res?.list) : res?.list);
            if (res?.list?.length < 6) setShouldLoadMore(false);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() =>
    {
        fetchContributorData();
        fetchProductList();
    }, []);

    useEffect(() =>
    {
        if (pageNumber !== 1)
        {
            fetchProductList(true);
        }
    }, [pageNumber]);

    return (
        <Section>
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? contributorData?.name_ar : contributorData?.name_en}
                </p>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xl flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-auto">
                        <Avatar src={contributorData?.thumbnail_url} size="w-48 h-48"/>
                    </div>
                    <div className="w-full md:w-[66.66%] font-secondary">
                        {BasicContent("common.name", prefs?.lang === constant.lang.values[0] ? contributorData?.name_ar : contributorData?.name_en)}
                    </div>
                </div>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xxl mb-grid-xxl">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                        {strings(`common.editions`)}
                    </p>
                    <div className="mt-grid-xl gap-grid-sm lg:gap-grid-lg items-start justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                        {productList?.map((product, index) => (
                            <div key={index} className="flex gap-grid-sm md:gap-grid-xl">
                                <div className="w-[30px] md:w-[50px] relative px-grid-sm md:px-grid-lg border-e border-dashed border-secondary-darkest">
                                    <div className="absolute top-grid-xxs end-[-6px]">
                                        <div className="flex items-center gap-grid-xxs">
                                            <div className="font-primary text-secondary-darkest text-sm">
                                                {getDate(prefs?.lang, product.produced_at, "YYYY")}
                                            </div>
                                            <div className="w-[12px] h-[12px] rounded-full flex items-center justify-center bg-secondary-darkest">
                                                <div className="w-[5.5px] h-[5.5px] rounded-full bg-primary-lightest"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${index !== productList?.length - 1 && "pb-40"} flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-start`}>
                                    <div className="w-[200px]">
                                        <Link to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${product?.id}`}>
                                            <CachedImg src={product?.thumbnail_url} alt={prefs?.lang === constant.lang.values[0] ? product?.seo_slug_ar : product?.seo_slug_en} className="min-w-[200px] h-[300px] bg-primary-light"/>
                                        </Link>
                                    </div>
                                    <div className="w-full md:w-[66.66%] font-secondary text-base">
                                        <p className="font-primary text-xl">
                                            {prefs?.lang === constant.lang.values[0] ? product?.name_ar : product?.name_en}
                                        </p>
                                        {BasicContentList("common.theProducer", product?.producers, true, "id", "producer")}
                                        {BasicContent("common.producedAt", getDate(prefs?.lang, product?.produced_at))}
                                        {BasicContent("common.isbn", product?.global_identifier)}
                                        {ContentWithBG("common.languages", product?.lang_code === "ar" ? strings("language.ar") : strings("language.en"), false)}
                                        {BasicContent("common.pagesCount", product?.pages_count)}
                                        {ContentWithBG("common.category", product?.subject)}
                                        {ContentWithBG("common.type", prefs?.lang === constant.lang.values[0] ? product?.form?.name_ar : product?.form?.name_en, false)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {shouldLoadMore &&
                        (
                            <div className="text-center my-grid-xxl">
                                <Button
                                    text={strings("common.loadMore")}
                                    bgColor={"bg-transparent"}
                                    hoverBGColor={"hover:bg-accent-lightest"}
                                    textColor={"text-accent-lightest"}
                                    hoverTextColor={"hover:text-primary-regular"}
                                    border={"border-2 border-accent-lightest"}
                                    hoverBorder={"hover:border-2 hover:border-transparent"}
                                    onClick={loadMore}
                                />
                            </div>
                        )}
                </div>
            </div>
        </Section>
    );
};

export default Contributor;