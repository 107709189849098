import React, { useContext, useEffect, useState } from "react";

import Table from "../view/component/table";
import Button from "../view/component/button";
import DropdownMenu from "../view/component/dropdownMenu";

import { SessionContext } from "../util/context";
import { getDateISO, strings } from "../util/formatter";
import { request } from "../util/http";
import endpoint from "../config/endpoint";
import constant from "../config/constant";

const ADD_PRODUCT_OPTIONS = [
    {
        label: "button.addProduct",
        key: "single"
    },
    {
        label: "common.uploadBulkOfProducts",
        key: "bulk"
    }
];

const ProducerProductList = () =>
{
    const { prefs, setActiveModal, setModalOptions } = useContext(SessionContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [productList, setProductList] = useState([]);
    const [isMaxPageNumber, setIsMaxPageNumber] = useState(false);

    const loadMore = (type) =>
    {
        if (type === "next")
        {
            setPageNumber(prevState => prevState + 1);
        }
        else
        {
            setPageNumber(prevState => prevState - 1);
            setIsMaxPageNumber(false);
        }
    };

    const addBookDropdownClick = (item) =>
    {
        if (item?.key === 'single')
        {
            setActiveModal("addProduct");
            setModalOptions({
                showStepsBar: true,
                currentStep: 1,
                totalSteps: 6,
                title: strings("title.addProduct")
            });
        }
        else
        {
            setActiveModal("uploadBulkOfProducts");
            setModalOptions({
                title: strings("title.uploadBulkOfProducts")
            });
        }
    }

    const fetchProductList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.list}?page=${pageNumber}&timestamp=${getDateISO()}`);
            if (res?.list?.length === 0)
            {
                setIsMaxPageNumber(true);
            }
            else
            {
                setProductList(res?.list);
            }
            setIsLoading(false);
        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        if ((pageNumber !== 1) || (pageNumber === 1 && productList?.length > 0))
        {
            fetchProductList();
        }
    }, [pageNumber]);

    useEffect(() =>
    {
        fetchProductList();
    }, []);

    return (
        <div className="p-grid-md">
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-lg font-primary text-neutral-low-dark transition-colors duration-fast ease-in">
                    {strings("title.productList")}
                </p>
                <div className="flex self-end items-center gap-grid-sm">
                    <DropdownMenu
                        useLocalization={true}
                        list={ADD_PRODUCT_OPTIONS}
                        labelKey="label"
                        placeholder={strings("button.addProduct")}
                        bgColor="bg-neutral-low-darkest"
                        textColor="text-neutral-high-lightest transition-colors duration-fast ease-in"
                        iconStroke="stroke-neutral-high-lightest"
                        font="text-md font-secondary"
                        roundedStyle="rounded-full"
                        optionalStyle="border-2 border-transparent"
                        padding="py-2 px-4"
                        gap="gap-grid-xs"
                        setSelected={addBookDropdownClick}
                    />
                    <Button
                        text={strings("button.addContributor")}
                        bgColor="bg-neutral-low-darkest"
                        hoverBGColor="hover:bg-transparent"
                        textColor="text-neutral-high-lightest"
                        hoverTextColor="hover:text-neutral-low-darkest"
                        hoverBorder="hover:border-2 hover:border-neutral-low-darkest"
                        border="border-2 border-transparent"
                        font="text-sm sm:text-md font-secondary"
                        onClick={() =>
                        {
                            setActiveModal("addContributor");
                            setModalOptions({
                                title: strings("title.addContributor")
                            });
                        }}
                    />
                </div>
            </div>
            <Table
                loading={isLoading}
                titles={productList?.length > 0 ? Object.keys(productList[0])?.filter(title => !["id"]?.includes(title)) : []}
                tableData={productList?.filter(title => !["id"]?.includes(title))}
                responsive={
                    {
                        maxXL: ["global_identifier", prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en", "price", "produced_at"],
                        maxLG: ["global_identifier", prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en", "price", "produced_at"],
                        maxMD: ["global_identifier", prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en", "price", "produced_at"],
                        maxSM: ["global_identifier", prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en", "price"],
                        maxXS: ["global_identifier", prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"]
                    }
                }
                columnType={
                    {
                        date: ["produced_at"],
                        price: ["price"]
                    }
                }
                sort={["global_identifier", prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en", "price", "produced_at"]}
                pageNumber={pageNumber}
                isMaxPageNumber={isMaxPageNumber}
                loadMore={loadMore}
            />
        </div>
    );
};

export default ProducerProductList;