import React, { useContext, useEffect, useState } from "react";

import Section from "../view/component/section";
import Avatar from "../view/component/avatar";

import ChartContestantSmall from "../view/component/chartContestantSmall";
import Button from "../view/component/button";
import Loader from "../view/component/loader";

import constant from "../config/constant";
import endpoint from "../config/endpoint";
import { SessionContext } from "../util/context";
import { getDateISO, strings } from "../util/formatter";
import { request } from "../util/http";

const Producer = () =>
{
    const { prefs } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [producerData, setProducerData] = useState({});
    const [productList, setProductList] = useState([]);

    const ITEM = {
        id: 1,
        logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
        name: "دار ميلاد للنشر والتوزيع",
        commercial_register: "101097537535",
        country: "المملكة العربية السعودية",
        languages: [
            "العربية",
            "الانجليزية"
        ],
        categories: [
            "رواية",
            "رحلات",
            "تاريخ"
        ],
        products: [
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 5,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 6,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 7,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 8,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 9,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 10,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 11,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 12,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 13,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 14,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 15,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 16,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 17,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 18,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 19,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 20,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            }
        ]
    };
    const ITEM_EN = {
        id: 1,
        logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
        name: "Melad Publishing and Distribution House",
        commercial_register: "101097537535",
        country: "Saudi Arabia",
        languages: [
            "Arabic",
            "English"
        ],
        categories: [
            "Novel",
            "Trips",
            "History"
        ],
        products: [
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 5,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 6,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 7,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 8,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 9,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 10,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 11,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 12,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 13,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 14,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 15,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 16,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 17,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 18,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 19,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 20,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            }
        ]
    };
    const [list, setList] = useState(prefs?.lang === "ar" ? ITEM?.products : ITEM_EN?.products);

    const loadMore = () =>
    {
        if (shouldLoadMore)
        {
            setPageNumber(pageNumber + 1);
        }
    };

    const BasicContent = (title, item) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <p>
                    {item}
                </p>
            </div>
        );
    };

    const ContentWithBG = (title, data, isList = true) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {isList
                    ? data?.length > 0 && data?.map((item, index) => (
                            <p key={index} className="px-grid-md py-grid-xxs bg-primary-light text-secondary-darkest text-center rounded-xl">
                                {prefs?.lang === constant.lang.values[0] ? data[index]?.name_ar : data[index]?.name_en}
                            </p>
                        ))
                    : <p className="px-grid-md py-grid-xxs bg-primary-light text-secondary-darkest text-center rounded-xl">
                            {data}
                        </p>
                    }
                </div>
            </div>
        );
    };

    const fetchProducerData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.producer.data}?producer_id=674f92b8524a9f2471d6f474`);
            setProducerData(res?.info);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    const fetchProductList = async (isLoadMore = false) =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.list}?page=${pageNumber}&timestamp=${getDateISO()}&sort_type=produced_at&producer_id=674f92b8524a9f2471d6f474`);
            setProductList(isLoadMore ? productList?.concat(res?.list) : res?.list);
            if (res?.list?.length < 6) setShouldLoadMore(false);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() =>
    {
        fetchProducerData();
        fetchProductList();
    }, []);

    useEffect(() =>
    {
        if (pageNumber !== 1)
        {
            fetchProductList(true);
        }
    }, [pageNumber]);

    return (
        <Section>
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? producerData?.name_ar : producerData?.name_en}
                </p>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xl flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-center justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-auto">
                        <Avatar src={producerData?.thumbnail_url} size="w-48 h-48"/>
                    </div>
                    <div className="w-full md:w-[66.66%] font-secondary">
                        {BasicContent("common.name", prefs?.lang === constant.lang.values[0] ? producerData?.name_ar : producerData?.name_en)}
                        {BasicContent("common.country", ITEM.country, ITEM_EN.country)}
                    </div>
                </div>
                <div className="mx-grid-lg md:mx-auto md:container my-grid-xxl">
                    <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                        {strings(`common.editions`)}
                    </p>
                    <div className="mt-grid-sm grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-grid-xl sm:gap-grid-sm md:gap-grid-xl py-grid-lg justify-items-center md:justify-start lg:justify-center">
                        {productList?.map((product, index) => (
                            <ChartContestantSmall
                                key={index}
                                item={product}
                                containerClassName="min-h-[400px] max-w-[280px] max-h-[400px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                imgClassName="w-[150px] h-[210px]"
                                textClassName="pt-grid-xs text-center"
                                imgKey="thumbnail_url"
                                titleKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                            />
                        ))}
                    </div>
                </div>
                {shouldLoadMore &&
                (
                    <div className="text-center my-grid-xxl">
                        <Button
                            text={strings("common.loadMore")}
                            bgColor={"bg-transparent"}
                            hoverBGColor={"hover:bg-accent-lightest"}
                            textColor={"text-accent-lightest"}
                            hoverTextColor={"hover:text-primary-regular"}
                            border={"border-2 border-accent-lightest"}
                            hoverBorder={"hover:border-2 hover:border-transparent"}
                            onClick={loadMore}
                        />
                    </div>
                )}
            </div>
        </Section>
    );
};

export default Producer;