const ICONS = {
    bellWithDot: [
        { path: "M12.0206 2.91016C8.71058 2.91016 6.02058 5.60016 6.02058 8.91016V11.8002C6.02058 12.4102 5.76058 13.3402 5.45058 13.8602L4.30058 15.7702C3.59058 16.9502 4.08058 18.2602 5.38058 18.7002C9.69058 20.1402 14.3406 20.1402 18.6506 18.7002C19.8606 18.3002 20.3906 16.8702 19.7306 15.7702L18.5806 13.8602C18.2806 13.3402 18.0206 12.4102 18.0206 11.8002V8.91016C18.0206 5.61016 15.3206 2.91016 12.0206 2.91016Z", stroke: "currentColor", strokeWidth:"1.5", strokeMiterlimit: "10", strokeLinecap: "round" },
        { path: "M13.8699 3.2C13.5599 3.11 13.2399 3.04 12.9099 3C11.9499 2.88 11.0299 2.95 10.1699 3.2C10.4599 2.46 11.1799 1.94 12.0199 1.94C12.8599 1.94 13.5799 2.46 13.8699 3.2Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M15.0195 19.0601C15.0195 20.7101 13.6695 22.0601 12.0195 22.0601C11.1995 22.0601 10.4395 21.7201 9.89953 21.1801C9.35953 20.6401 9.01953 19.8801 9.01953 19.0601", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10" },
        { ellipse: { cx: "16.4559", cy: "4", rx: "4.0182", ry: "4" }, fill: "#E74C3C" }
    ],
    bell: [
        { path: "M12.0206 2.91016C8.71058 2.91016 6.02058 5.60016 6.02058 8.91016V11.8002C6.02058 12.4102 5.76058 13.3402 5.45058 13.8602L4.30058 15.7702C3.59058 16.9502 4.08058 18.2602 5.38058 18.7002C9.69058 20.1402 14.3406 20.1402 18.6506 18.7002C19.8606 18.3002 20.3906 16.8702 19.7306 15.7702L18.5806 13.8602C18.2806 13.3402 18.0206 12.4102 18.0206 11.8002V8.91016C18.0206 5.61016 15.3206 2.91016 12.0206 2.91016Z", stroke: "currentColor", strokeWidth:"1.5", strokeMiterlimit: "10", strokeLinecap: "round" },
        { path: "M13.8699 3.2C13.5599 3.11 13.2399 3.04 12.9099 3C11.9499 2.88 11.0299 2.95 10.1699 3.2C10.4599 2.46 11.1799 1.94 12.0199 1.94C12.8599 1.94 13.5799 2.46 13.8699 3.2Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M15.0195 19.0601C15.0195 20.7101 13.6695 22.0601 12.0195 22.0601C11.1995 22.0601 10.4395 21.7201 9.89953 21.1801C9.35953 20.6401 9.01953 19.8801 9.01953 19.0601", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10" }
    ],
    bookmark: [
        { path: "M9.25 9.04999C11.03 9.69999 12.97 9.69999 14.75 9.04999", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap:"round", strokeLinejoin:"round" },
        { path: "M16.8203 2H7.18031C5.05031 2 3.32031 3.74 3.32031 5.86V19.95C3.32031 21.75 4.61031 22.51 6.19031 21.64L11.0703 18.93C11.5903 18.64 12.4303 18.64 12.9403 18.93L17.8203 21.64C19.4003 22.52 20.6903 21.76 20.6903 19.95V5.86C20.6803 3.74 18.9503 2 16.8203 2Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M16.8203 2H7.18031C5.05031 2 3.32031 3.74 3.32031 5.86V19.95C3.32031 21.75 4.61031 22.51 6.19031 21.64L11.0703 18.93C11.5903 18.64 12.4303 18.64 12.9403 18.93L17.8203 21.64C19.4003 22.52 20.6903 21.76 20.6903 19.95V5.86C20.6803 3.74 18.9503 2 16.8203 2Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    calender: [
        { path: "M18.5 4H16.5V3.5C16.5 3.36739 16.4473 3.24021 16.3536 3.14645C16.2598 3.05268 16.1326 3 16 3C15.8674 3 15.7402 3.05268 15.6464 3.14645C15.5527 3.24021 15.5 3.36739 15.5 3.5V4H8.5V3.5C8.5 3.36739 8.44732 3.24021 8.35355 3.14645C8.25979 3.05268 8.13261 3 8 3C7.86739 3 7.74021 3.05268 7.64645 3.14645C7.55268 3.24021 7.5 3.36739 7.5 3.5V4H5.5C4.83696 4 4.20107 4.26339 3.73223 4.73223C3.26339 5.20107 3 5.83696 3 6.5V18.5C3 19.163 3.26339 19.7989 3.73223 20.2678C4.20107 20.7366 4.83696 21 5.5 21H18.5C19.163 21 19.7989 20.7366 20.2678 20.2678C20.7366 19.7989 21 19.163 21 18.5V6.5C21 5.83696 20.7366 5.20107 20.2678 4.73223C19.7989 4.26339 19.163 4 18.5 4ZM5.5 5H7.5V5.5C7.5 5.63261 7.55268 5.75979 7.64645 5.85355C7.74021 5.94732 7.86739 6 8 6C8.13261 6 8.25979 5.94732 8.35355 5.85355C8.44732 5.75979 8.5 5.63261 8.5 5.5V5H15.5V5.5C15.5 5.63261 15.5527 5.75979 15.6464 5.85355C15.7402 5.94732 15.8674 6 16 6C16.1326 6 16.2598 5.94732 16.3536 5.85355C16.4473 5.75979 16.5 5.63261 16.5 5.5V5H18.5C18.8978 5 19.2794 5.15804 19.5607 5.43934C19.842 5.72064 20 6.10218 20 6.5V8H4V6.5C4 6.10218 4.15804 5.72064 4.43934 5.43934C4.72064 5.15804 5.10218 5 5.5 5ZM18.5 20H5.5C5.10218 20 4.72064 19.842 4.43934 19.5607C4.15804 19.2794 4 18.8978 4 18.5V9H20V18.5C20 18.8978 19.842 19.2794 19.5607 19.5607C19.2794 19.842 18.8978 20 18.5 20Z", fill: "currentColor" },
        { path: "M8 11H7C6.86739 11 6.74021 11.0527 6.64645 11.1464C6.55268 11.2402 6.5 11.3674 6.5 11.5C6.5 11.6326 6.55268 11.7598 6.64645 11.8536C6.74021 11.9473 6.86739 12 7 12H8C8.13261 12 8.25979 11.9473 8.35355 11.8536C8.44732 11.7598 8.5 11.6326 8.5 11.5C8.5 11.3674 8.44732 11.2402 8.35355 11.1464C8.25979 11.0527 8.13261 11 8 11Z", fill: "currentColor" },
        { path: "M8 14H7C6.86739 14 6.74021 14.0527 6.64645 14.1464C6.55268 14.2402 6.5 14.3674 6.5 14.5C6.5 14.6326 6.55268 14.7598 6.64645 14.8536C6.74021 14.9473 6.86739 15 7 15H8C8.13261 15 8.25979 14.9473 8.35355 14.8536C8.44732 14.7598 8.5 14.6326 8.5 14.5C8.5 14.3674 8.44732 14.2402 8.35355 14.1464C8.25979 14.0527 8.13261 14 8 14Z", fill: "currentColor" },
        { path: "M8 17H7C6.86739 17 6.74021 17.0527 6.64645 17.1464C6.55268 17.2402 6.5 17.3674 6.5 17.5C6.5 17.6326 6.55268 17.7598 6.64645 17.8536C6.74021 17.9473 6.86739 18 7 18H8C8.13261 18 8.25979 17.9473 8.35355 17.8536C8.44732 17.7598 8.5 17.6326 8.5 17.5C8.5 17.3674 8.44732 17.2402 8.35355 17.1464C8.25979 17.0527 8.13261 17 8 17Z", fill: "currentColor" },
        { path: "M12.5 11H11.5C11.3674 11 11.2402 11.0527 11.1464 11.1464C11.0527 11.2402 11 11.3674 11 11.5C11 11.6326 11.0527 11.7598 11.1464 11.8536C11.2402 11.9473 11.3674 12 11.5 12H12.5C12.6326 12 12.7598 11.9473 12.8536 11.8536C12.9473 11.7598 13 11.6326 13 11.5C13 11.3674 12.9473 11.2402 12.8536 11.1464C12.7598 11.0527 12.6326 11 12.5 11Z", fill: "currentColor" },
        { path: "M12.5 14H11.5C11.3674 14 11.2402 14.0527 11.1464 14.1464C11.0527 14.2402 11 14.3674 11 14.5C11 14.6326 11.0527 14.7598 11.1464 14.8536C11.2402 14.9473 11.3674 15 11.5 15H12.5C12.6326 15 12.7598 14.9473 12.8536 14.8536C12.9473 14.7598 13 14.6326 13 14.5C13 14.3674 12.9473 14.2402 12.8536 14.1464C12.7598 14.0527 12.6326 14 12.5 14Z", fill: "currentColor" },
        { path: "M12.5 17H11.5C11.3674 17 11.2402 17.0527 11.1464 17.1464C11.0527 17.2402 11 17.3674 11 17.5C11 17.6326 11.0527 17.7598 11.1464 17.8536C11.2402 17.9473 11.3674 18 11.5 18H12.5C12.6326 18 12.7598 17.9473 12.8536 17.8536C12.9473 17.7598 13 17.6326 13 17.5C13 17.3674 12.9473 17.2402 12.8536 17.1464C12.7598 17.0527 12.6326 17 12.5 17Z", fill: "currentColor" },
        { path: "M17 11H16C15.8674 11 15.7402 11.0527 15.6464 11.1464C15.5527 11.2402 15.5 11.3674 15.5 11.5C15.5 11.6326 15.5527 11.7598 15.6464 11.8536C15.7402 11.9473 15.8674 12 16 12H17C17.1326 12 17.2598 11.9473 17.3536 11.8536C17.4473 11.7598 17.5 11.6326 17.5 11.5C17.5 11.3674 17.4473 11.2402 17.3536 11.1464C17.2598 11.0527 17.1326 11 17 11Z", fill: "currentColor" },
        { path: "M17 14H16C15.8674 14 15.7402 14.0527 15.6464 14.1464C15.5527 14.2402 15.5 14.3674 15.5 14.5C15.5 14.6326 15.5527 14.7598 15.6464 14.8536C15.7402 14.9473 15.8674 15 16 15H17C17.1326 15 17.2598 14.9473 17.3536 14.8536C17.4473 14.7598 17.5 14.6326 17.5 14.5C17.5 14.3674 17.4473 14.2402 17.3536 14.1464C17.2598 14.0527 17.1326 14 17 14Z", fill: "currentColor" },
        { path: "M17 17H16C15.8674 17 15.7402 17.0527 15.6464 17.1464C15.5527 17.2402 15.5 17.3674 15.5 17.5C15.5 17.6326 15.5527 17.7598 15.6464 17.8536C15.7402 17.9473 15.8674 18 16 18H17C17.1326 18 17.2598 17.9473 17.3536 17.8536C17.4473 17.7598 17.5 17.6326 17.5 17.5C17.5 17.3674 17.4473 17.2402 17.3536 17.1464C17.2598 17.0527 17.1326 17 17 17Z", fill: "currentColor" }
    ],
    cart: [
        { path: "M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M9 8H21", stroke:"currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    chevron: [
        { path: "M8.90991 19.92L15.4299 13.4C16.1999 12.63 16.1999 11.37 15.4299 10.6L8.90991 4.07999", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }

    ],
    home: [
        { path: "M9.02 2.83998L3.63 7.03998C2.73 7.73998 2 9.22998 2 10.36V17.77C2 20.09 3.89 21.99 6.21 21.99H17.79C20.11 21.99 22 20.09 22 17.78V10.5C22 9.28998 21.19 7.73998 20.2 7.04998L14.02 2.71998C12.62 1.73998 10.37 1.78998 9.02 2.83998Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12 17.99V14.99", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    house: [
        { path: "M2 22H22", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M2.94922 22.0003L2.99922 9.97023C2.99922 9.36023 3.28922 8.78029 3.76922 8.40029L10.7692 2.95027C11.4892 2.39027 12.4992 2.39027 13.2292 2.95027L20.2292 8.39028C20.7192 8.77028 20.9992 9.35023 20.9992 9.97023V22.0003", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinejoin: "round" },
        { path: "M13 17H11C10.17 17 9.5 17.67 9.5 18.5V22H14.5V18.5C14.5 17.67 13.83 17 13 17Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinejoin: "round" },
        { path: "M9.5 13.75H7.5C6.95 13.75 6.5 13.3 6.5 12.75V11.25C6.5 10.7 6.95 10.25 7.5 10.25H9.5C10.05 10.25 10.5 10.7 10.5 11.25V12.75C10.5 13.3 10.05 13.75 9.5 13.75Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinejoin: "round" },
        { path: "M16.5 13.75H14.5C13.95 13.75 13.5 13.3 13.5 12.75V11.25C13.5 10.7 13.95 10.25 14.5 10.25H16.5C17.05 10.25 17.5 10.7 17.5 11.25V12.75C17.5 13.3 17.05 13.75 16.5 13.75Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinejoin: "round" },
        { path: "M19.0003 7L18.9703 4H14.5703", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    magnifer: [
        { path: "M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M22 22L20 20", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    minus: [
        { path: "M6 12H18", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    moon: [
        { path: "M2.02911 12.42C2.38911 17.57 6.75911 21.76 11.9891 21.99C15.6791 22.15 18.9791 20.43 20.9591 17.72C21.7791 16.61 21.3391 15.87 19.9691 16.12C19.2991 16.24 18.6091 16.29 17.8891 16.26C12.9991 16.06 8.99911 11.97 8.97911 7.13996C8.96911 5.83996 9.23911 4.60996 9.72911 3.48996C10.2691 2.24996 9.61911 1.65996 8.36911 2.18996C4.40911 3.85996 1.69911 7.84996 2.02911 12.42Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    sun: [
        { path: "M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M19.14 19.14L19.01 19.01M19.01 4.99L19.14 4.86L19.01 4.99ZM4.86 19.14L4.99 19.01L4.86 19.14ZM12 2.08V2V2.08ZM12 22V21.92V22ZM2.08 12H2H2.08ZM22 12H21.92H22ZM4.99 4.99L4.86 4.86L4.99 4.99Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    book: [
        { path: "M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12 5.48999V20.49", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M7.75 8.48999H5.5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M8.5 11.49H5.5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    shop: [
        { path: "M3.01001 11.22V15.71C3.01001 20.2 4.81001 22 9.30001 22H14.69C19.18 22 20.98 20.2 20.98 15.71V11.22", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12.0005 12C13.8305 12 15.1805 10.51 15.0005 8.68L14.3405 2H9.67048L9.00048 8.68C8.82048 10.51 10.1705 12 12.0005 12Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M18.31 12C20.33 12 21.81 10.36 21.61 8.35L21.33 5.6C20.97 3 19.97 2 17.35 2H14.3L15 9.01C15.17 10.66 16.66 12 18.31 12Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M5.64037 12C7.29037 12 8.78037 10.66 8.94037 9.01L9.16037 6.8L9.64037 2H6.59037C3.97037 2 2.97037 3 2.61037 5.6L2.34037 8.35C2.14037 10.36 3.62037 12 5.64037 12Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    star: [
        { path: "M13.7299 3.51001L15.4899 7.03001C15.7299 7.52002 16.3699 7.99001 16.9099 8.08001L20.0999 8.61001C22.1399 8.95001 22.6199 10.43 21.1499 11.89L18.6699 14.37C18.2499 14.79 18.0199 15.6 18.1499 16.18L18.8599 19.25C19.4199 21.68 18.1299 22.62 15.9799 21.35L12.9899 19.58C12.4499 19.26 11.5599 19.26 11.0099 19.58L8.01991 21.35C5.87991 22.62 4.57991 21.67 5.13991 19.25L5.84991 16.18C5.97991 15.6 5.74991 14.79 5.32991 14.37L2.84991 11.89C1.38991 10.43 1.85991 8.95001 3.89991 8.61001L7.08991 8.08001C7.61991 7.99001 8.25991 7.52002 8.49991 7.03001L10.2599 3.51001C11.2199 1.60001 12.7799 1.60001 13.7299 3.51001Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    upload: [
        { path: "M9.32007 6.5L11.8801 3.94L14.4401 6.5", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M11.8799 14.18V4.01", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    arrow: [
        { path: "M9.57 5.92999L3.5 12L9.57 18.07", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M20.4999 12H3.66992", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    safe: [
        { path: "M22.9998 15.37V17.5601C22.9998 20.0001 21.2198 22.3 18.7998 22.97C18.6298 23.01 18.4499 23.01 18.2899 22.97C17.1099 22.65 16.0698 21.93 15.3398 21C14.5498 20.02 14.0898 18.8101 14.0898 17.5601V15.37C14.0898 14.95 14.3998 14.49 14.7798 14.33L17.5598 13.1899C18.1898 12.9399 18.8898 12.9399 19.5198 13.1899L20.5198 13.6L22.3098 14.33C22.6898 14.49 22.9998 14.95 22.9998 15.37Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M20.9493 11.0103L20.5193 13.6003L19.5193 13.1902C18.8893 12.9402 18.1893 12.9402 17.5593 13.1902L14.7793 14.3302C14.3993 14.4902 14.0893 14.9503 14.0893 15.3703V17.5604C14.0893 18.8104 14.5493 20.0203 15.3393 21.0003H6.1793C4.8093 21.0003 3.51931 19.9104 3.28931 18.5604L2.0293 11.0103C1.8693 10.0803 2.33929 8.83028 3.07929 8.24028L9.65931 2.98027C10.6693 2.17027 12.3093 2.17028 13.3193 2.99028L19.8993 8.24028C20.6293 8.83028 21.1093 10.0803 20.9493 11.0103Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    clock: [
        { path: "M12 9.65997V12.45L13.4 13.85", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M5.5 12C5.5 8.41 8.41 5.5 12 5.5C15.59 5.5 18.5 8.41 18.5 12C18.5 14.08 17.52 15.94 16 17.13H15.99C14.89 17.99 13.51 18.5 12 18.5C10.51 18.5 9.14 18 8.04 17.15H8.03C6.49 15.96 5.5 14.1 5.5 12Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M8.03027 17.1499H8.04027C9.14027 17.9999 10.5103 18.4999 12.0003 18.4999C13.5103 18.4999 14.8903 17.9899 15.9903 17.1299H16.0003L15.4903 19.5999C15.0003 21.4999 13.9003 21.9999 12.5503 21.9999H11.4603C10.1103 21.9999 9.00027 21.4999 8.52027 19.5899L8.03027 17.1499Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M8.03027 6.85H8.04027C9.14027 6 10.5103 5.5 12.0003 5.5C13.5103 5.5 14.8903 6.01 15.9903 6.87H16.0003L15.4903 4.4C15.0003 2.5 13.9003 2 12.5503 2H11.4603C10.1103 2 9.00027 2.5 8.52027 4.41L8.03027 6.85Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    building1: [
        { path: "M6.70001 18H4.15002C2.72002 18 2 17.28 2 15.85V4.15002C2 2.72002 2.72002 2 4.15002 2H8.45001C9.88001 2 10.6 2.72002 10.6 4.15002V6", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M17.3692 8.41998V19.58C17.3692 21.19 16.5692 22 14.9592 22H9.1192C7.5092 22 6.69922 21.19 6.69922 19.58V8.41998C6.69922 6.80998 7.5092 6 9.1192 6H14.9592C16.5692 6 17.3692 6.80998 17.3692 8.41998Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M13.4004 6V4.15002C13.4004 2.72002 14.1204 2 15.5504 2H19.8503C21.2803 2 22.0004 2.72002 22.0004 4.15002V15.85C22.0004 17.28 21.2803 18 19.8503 18H17.3704", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M10 11H14", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M10 14H14", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12 22V19", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    building2: [
        { path: "M13 22H5C3 22 2 21 2 19V11C2 9 3 8 5 8H10V19C10 21 11 22 13 22Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M10.11 4C10.03 4.3 10 4.63 10 5V8H5V6C5 4.9 5.9 4 7 4H10.11Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M14 8V13", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M18 8V13", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M17 17H15C14.45 17 14 17.45 14 18V22H18V18C18 17.45 17.55 17 17 17Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M6 13V17", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M10 19V5C10 3 11 2 13 2H19C21 2 22 3 22 5V19C22 21 21 22 19 22H13C11 22 10 21 10 19Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    bag1: [
        { path: "M8.40002 6.5H15.6C19 6.5 19.34 8.09 19.57 10.03L20.47 17.53C20.76 19.99 20 22 16.5 22H7.51003C4.00003 22 3.24002 19.99 3.54002 17.53L4.44003 10.03C4.66003 8.09 5.00002 6.5 8.40002 6.5Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M8 8V4.5C8 3 9 2 10.5 2H13.5C15 2 16 3 16 4.5V8", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M20.41 17.03H8", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    bag2: [
        { path: "M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M9.00007 22H15.0001C19.0201 22 19.7401 20.39 19.9501 18.43L20.7001 12.43C20.9701 9.99 20.2701 8 16.0001 8H8.00007C3.73007 8 3.03007 9.99 3.30007 12.43L4.05007 18.43C4.26007 20.39 4.98007 22 9.00007 22Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M15.4955 12H15.5045", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M8.49451 12H8.50349", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    dollar: [
        { path: "M12 6V18", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round" },
        { path: "M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round" }
    ],
    circleDollar: [
        { fillRule: "evenodd", clipRule: "evenodd", path: "M9.61187 7.08984C8.98769 7.08984 8.42188 7.63173 8.42188 8.36984C8.42188 8.87252 8.53009 9.10244 8.62588 9.22464C8.72842 9.35544 8.90085 9.47164 9.24032 9.59218L13.2724 10.9929C13.7216 11.1521 14.2086 11.3907 14.5684 11.8496C14.9351 12.3174 15.0919 12.9125 15.0919 13.6298C15.0919 15.1317 13.9177 16.4098 12.4019 16.4098H9.89188C8.2137 16.4098 6.92188 14.9993 6.92188 13.3298C6.92188 12.9156 7.25766 12.5798 7.67188 12.5798C8.08609 12.5798 8.42188 12.9156 8.42188 13.3298C8.42188 14.2404 9.11005 14.9098 9.89188 14.9098H12.4019C13.0261 14.9098 13.5919 14.368 13.5919 13.6298C13.5919 13.1272 13.4837 12.8972 13.3879 12.775C13.2853 12.6442 13.1129 12.5281 12.7735 12.4075L8.74132 11.0068C8.29218 10.8476 7.80512 10.609 7.44537 10.15C7.07866 9.68225 6.92188 9.08717 6.92188 8.36984C6.92188 6.86796 8.09606 5.58984 9.61187 5.58984H12.1219C13.8 5.58984 15.0919 7.00043 15.0919 8.66984C15.0919 9.08406 14.7561 9.41984 14.3419 9.41984C13.9277 9.41984 13.5919 9.08406 13.5919 8.66984C13.5919 7.75926 12.9037 7.08984 12.1219 7.08984H9.61187Z", fill: "currentColor" },
        { fillRule: "evenodd", clipRule: "evenodd", path: "M11 4.25C11.4142 4.25 11.75 4.58579 11.75 5V17C11.75 17.4142 11.4142 17.75 11 17.75C10.5858 17.75 10.25 17.4142 10.25 17V5C10.25 4.58579 10.5858 4.25 11 4.25Z", fill: "currentColor" },
        { fillRule: "evenodd", clipRule: "evenodd", path: "M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11Z", fill: "currentColor" }
    ],
    pieChart: [
        { path: "M4.02 5.97C2.75 7.65 2 9.74 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M5 12C5 15.87 8.13 19 12 19C15.87 19 19 15.87 19 12C19 8.13 15.87 5 12 5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    share: [
        { path: "M16.9609 6.17004C18.9609 7.56004 20.3409 9.77004 20.6209 12.32", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M3.49023 12.37C3.75023 9.83 5.11023 7.62 7.09023 6.22", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M8.18945 20.9399C9.34945 21.5299 10.6695 21.8599 12.0595 21.8599C13.3995 21.8599 14.6595 21.5599 15.7895 21.0099", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12.0593 7.70001C13.5946 7.70001 14.8393 6.45537 14.8393 4.92001C14.8393 3.38466 13.5946 2.14001 12.0593 2.14001C10.5239 2.14001 9.2793 3.38466 9.2793 4.92001C9.2793 6.45537 10.5239 7.70001 12.0593 7.70001Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M4.83078 19.92C6.36613 19.92 7.61078 18.6753 7.61078 17.14C7.61078 15.6046 6.36613 14.36 4.83078 14.36C3.29543 14.36 2.05078 15.6046 2.05078 17.14C2.05078 18.6753 3.29543 19.92 4.83078 19.92Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M19.1706 19.92C20.706 19.92 21.9506 18.6753 21.9506 17.14C21.9506 15.6046 20.706 14.36 19.1706 14.36C17.6353 14.36 16.3906 15.6046 16.3906 17.14C16.3906 18.6753 17.6353 19.92 19.1706 19.92Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    lock: [
        { path: "M2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16Z", stroke: "currentColor", strokeWidth: "1.5" },
        { path: "M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z", stroke: "currentColor", strokeWidth: "1.5" },
        { path: "M6 10V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round" }
    ],
    close: [
        { path: "M15.9998 29.3334C23.3636 29.3334 29.3332 23.3639 29.3332 16.0001C29.3332 8.63628 23.3636 2.66675 15.9998 2.66675C8.63604 2.66675 2.6665 8.63628 2.6665 16.0001C2.6665 23.3639 8.63604 29.3334 15.9998 29.3334Z", stroke: "currentColor", strokeWidth: "1.5" },
        { path: "M19.3332 12.6668L12.6665 19.3334M12.6665 12.6667L19.3332 19.3334", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round" }
    ],
    rank: [
        { path: "M8.67 14H4C2.9 14 2 14.9 2 16V22H8.67V14Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M13.3302 10H10.6602C9.56016 10 8.66016 10.9 8.66016 12V22H15.3302V12C15.3302 10.9 14.4402 10 13.3302 10Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M20.0001 17H15.3301V22H22.0001V19C22.0001 17.9 21.1001 17 20.0001 17Z", stroke: "currentColor", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M12.5202 2.07001L13.0502 3.13C13.1202 3.28 13.3102 3.42 13.4702 3.44L14.4302 3.60001C15.0402 3.70001 15.1902 4.14999 14.7502 4.57999L14.0002 5.32999C13.8702 5.45999 13.8002 5.69999 13.8402 5.86999L14.0502 6.79001C14.2202 7.52001 13.8302 7.80001 13.1902 7.42001L12.2902 6.89001C12.1302 6.79001 11.8602 6.79001 11.7002 6.89001L10.8002 7.42001C10.1602 7.80001 9.77023 7.52001 9.94023 6.79001L10.1502 5.86999C10.1902 5.69999 10.1202 5.44999 9.99023 5.32999L9.25023 4.59C8.81023 4.15 8.95023 3.70998 9.57023 3.60998L10.5302 3.45001C10.6902 3.42001 10.8802 3.28001 10.9502 3.14001L11.4802 2.07999C11.7702 1.49999 12.2302 1.50001 12.5202 2.07001Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    checkMark: [
        { path: "M4.75502 10.2349L0.205017 5.68493C-0.0683389 5.41157 -0.0683389 4.96836 0.205017 4.69497L1.19494 3.70502C1.4683 3.43164 1.91154 3.43164 2.1849 3.70502L5.25 6.77009L11.8151 0.205017C12.0885 -0.0683389 12.5317 -0.0683389 12.8051 0.205017L13.795 1.19497C14.0683 1.46833 14.0683 1.91154 13.795 2.18492L5.74498 10.235C5.47159 10.5083 5.02838 10.5083 4.75502 10.2349Z", fill: "currentColor" }
    ],
    copy: [
        { path: "M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z", fill: "currentColor" }
    ],
    hamburger: [
        { path: "M2 6C2 4.89375 2.89375 4 4 4H28C29.1063 4 30 4.89375 30 6C30 7.10625 29.1063 8 28 8H4C2.89375 8 2 7.10625 2 6ZM2 16C2 14.8938 2.89375 14 4 14H28C29.1063 14 30 14.8938 30 16C30 17.1062 29.1063 18 28 18H4C2.89375 18 2 17.1062 2 16ZM30 26C30 27.1063 29.1063 28 28 28H4C2.89375 28 2 27.1063 2 26C2 24.8937 2.89375 24 4 24H28C29.1063 24 30 24.8937 30 26Z", fill: "currentColor" }
    ],
    closeInCircle: [
        { path: "M22 0C9.84678 0 0 9.84678 0 22C0 34.1532 9.84678 44 22 44C34.1532 44 44 34.1532 44 22C44 9.84678 34.1532 0 22 0ZM32.7871 27.775C33.204 28.1919 33.204 28.8661 32.7871 29.2831L29.2742 32.7871C28.8573 33.204 28.1831 33.204 27.7661 32.7871L22 26.9677L16.225 32.7871C15.8081 33.204 15.1339 33.204 14.7169 32.7871L11.2129 29.2742C10.796 28.8573 10.796 28.1831 11.2129 27.7661L17.0323 22L11.2129 16.225C10.796 15.8081 10.796 15.1339 11.2129 14.7169L14.7258 11.204C15.1427 10.7871 15.8169 10.7871 16.2339 11.204L22 17.0323L27.775 11.2129C28.1919 10.796 28.8661 10.796 29.2831 11.2129L32.796 14.7258C33.2129 15.1427 33.2129 15.8169 32.796 16.2339L26.9677 22L32.7871 27.775Z", fill: "currentColor" }
    ],
    addInCircle: [
        { path: "M6.44404 6.44365C-2.14958 15.0373 -2.14958 28.9627 6.44404 37.5563C15.0377 46.15 28.9631 46.15 37.5567 37.5563C46.1504 28.9627 46.1504 15.0373 37.5567 6.44365C28.9631 -2.14998 15.0377 -2.14998 6.44404 6.44365ZM33.7116 18.4559C34.3012 18.4559 34.7779 18.9326 34.7779 19.5223L34.7717 24.484C34.7717 25.0736 34.2949 25.5504 33.7053 25.5504L25.5131 25.5127L25.5445 33.7112C25.5445 34.3008 25.0678 34.7775 24.4781 34.7775L19.5164 34.7713C18.9268 34.7713 18.45 34.2945 18.45 33.7049L18.4877 25.5127L10.2892 25.5441C9.69959 25.5441 9.22286 25.0674 9.22286 24.4777L9.22286 19.5097C9.22286 18.9201 9.69959 18.4434 10.2892 18.4434L18.4877 18.4873L18.4563 10.2888C18.4563 9.69919 18.933 9.22247 19.5227 9.22246L24.4907 9.22247C25.0803 9.22247 25.557 9.69919 25.557 10.2888L25.5131 18.4873L33.7116 18.4559Z", fill: "#currentColor" }
    ],
    row: [
        { path: "M19.9 13.5H4.1C2.6 13.5 2 14.14 2 15.73V19.77C2 21.36 2.6 22 4.1 22H19.9C21.4 22 22 21.36 22 19.77V15.73C22 14.14 21.4 13.5 19.9 13.5Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M19.9 2H4.1C2.6 2 2 2.64 2 4.23V8.27C2 9.86 2.6 10.5 4.1 10.5H19.9C21.4 10.5 22 9.86 22 8.27V4.23C22 2.64 21.4 2 19.9 2Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    chart: [
        { path: "M2 22H22", stroke: "#3A3A3A", strokeWidth:"1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z", stroke: "#3A3A3A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z", stroke: "#3A3A3A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" },
        { path: "M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z", stroke: "#3A3A3A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }
    ],
    saudiRiyal: [
        { path: "M699.62,1113.02h0c-20.06,44.48-33.32,92.75-38.4,143.37l424.51-90.24c20.06-44.47,33.31-92.75,38.4-143.37l-424.51,90.24Z", fill: "currentColor" },
        { path: "M1085.73,895.8c20.06-44.47,33.32-92.75,38.4-143.37l-330.68,70.33v-135.2l292.27-62.11c20.06-44.47,33.32-92.75,38.4-143.37l-330.68,70.27V66.13c-50.67,28.45-95.67,66.32-132.25,110.99v403.35l-132.25,28.11V0c-50.67,28.44-95.67,66.32-132.25,110.99v525.69l-295.91,62.88c-20.06,44.47-33.33,92.75-38.42,143.37l334.33-71.05v170.26l-358.3,76.14c-20.06,44.47-33.32,92.75-38.4,143.37l375.04-79.7c30.53-6.35,56.77-24.4,73.83-49.24l68.78-101.97v-.02c7.14-10.55,11.3-23.27,11.3-36.97v-149.98l132.25-28.11v270.4l424.53-90.28Z", fill: "currentColor" }
    ]
};

export default ICONS;