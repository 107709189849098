import React, { useContext, useEffect, useState } from "react";

import Button from "../view/component/button";
import Tabs from "../view/component/tabs";
import ManageEntityDetails from "../view/component/manageEntityDetails";
import ManageEntityUsers from "../view/component/manageEntityUsers";
import ManageEntityLegal from "../view/component/manageEntityLegal";
import ManageEntitySubscriptions from "../view/component/manageEntitySubscriptions";
import ManageEntityIntegration from "../view/component/manageEntityIntegration";
import PriceToPriceWithCurrency from "../view/component/priceToPriceWithCurrency";

import { SessionContext } from "../util/context";
import { getDate, getDateISO, strings } from "../util/formatter";
import { isNotEmpty } from "../util/validator";
import { request } from "../util/http";
import endpoint from "../config/endpoint";
import constant from "../config/constant";
import { showNotification } from "../util/notification";

const CR_ISSUED_COUNTRY = [
    {
        id: 1,
        name: strings("country.sa"),
        country_code: "sa"
    }
];

const ManageEntity = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions, merchantInfo, setter, roleCode } = useContext(SessionContext);
    const [active, setActive] = useState(modalOptions?.active ? modalOptions?.active : "details");
    const [entityUsers, setEntityUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [entityName, setEntityName] = useState(merchantInfo?.display_name ? merchantInfo?.display_name : null);
    const [crNumber, setCRNumber] = useState(merchantInfo?.cr_number ? merchantInfo?.cr_number : null);
    const [crCountryCode, setCRCountryCode] = useState(merchantInfo?.country_code ? merchantInfo?.country_code : null);
    const [selectedCountry, setSelectedCountry] = useState(CR_ISSUED_COUNTRY?.filter(item => item.country_code === merchantInfo?.country_code)?.length > 0 ? CR_ISSUED_COUNTRY?.filter(item => item.country_code === merchantInfo?.country_code)?.[0] : {});
    const [crExpiryDate, setCRExpiryDate] = useState(merchantInfo?.cr_end_date ? merchantInfo?.cr_end_date : null);
    const [crFile, setCRFile] = useState({});
    const [userRole, setUserRole] = useState(constant.user_role[0]);
    const [removedUserId, setRemovedUserId] = useState(null);
    const [updatedRole, setUpdatedRole] = useState({});
    const [pageNum, setPageNum] = useState(1);
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [pricingData, setPricingData] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState({});
    const isValidForm = active === "details"
    ? isNotEmpty(entityName) && merchantInfo?.display_name !== entityName
    : true

    const loadMore = () =>
    {
        if (shouldLoadMore)
        {
            setPageNum(pageNum + 1);
        }
    };

    const setSelectedCRCountryCode = (item) =>
    {
        setCRCountryCode(item?.country_code);
        setSelectedCountry(item);
    };

    const save = async () =>
    {
        // setActiveModal(undefined);
        if (isValidForm)
        {
            setIsLoading(true);
            if (active === "details")
            {
                try
                {
                    const data = {
                        legal_name: entityName,
                        display_name: entityName
                    }
                    const queryString = Object.keys(data)
                    ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
                    ?.join("&");
                    const merchantDataPutRes = await request("PUT", `${endpoint.crm.merchant.data}?${queryString}`);
                    const merchantInfoObj = { ...merchantInfo, ...data };
                    setter("merchant_info", JSON.stringify(merchantInfoObj));
                    setIsLoading(false);
                    setActiveModal(undefined);
                }
                catch (error)
                {
                    setIsLoading(false);
                }
            }

            if (active === "legalEntity")
            {
                try
                {
                    const data = {
                        ...(isNotEmpty(crNumber) && crNumber !== merchantInfo?.cr_number && { cr_number: crNumber }),
                        ...(crCountryCode !== merchantInfo?.country_code && { country_code: crCountryCode }),
                        ...(getDate(prefs?.lang, crExpiryDate, "YYYY-MM-DD") !== merchantInfo?.cr_end_date && { cr_end_date: getDate(prefs?.lang, crExpiryDate, "YYYY-MM-DD") }),
                        ...(Object.keys(crFile)?.length > 0 && { cr_img: crFile })
                    }
                    const merchantCRPostRes = await request("POST", `${endpoint.crm.merchant.cr}`, data);
                    const merchantInfoObj = { ...merchantInfo, ...data };
                    setter("merchant_info", JSON.stringify(merchantInfoObj));
                    setIsLoading(false);
                    setActiveModal(undefined);
                }
                catch (error)
                {
                    setIsLoading(false);
                }
            }
        }
    };

    const fetchMerchantUser = async (isLoadMore = false) =>
    {
        try
        {
            const res = await request("GET", `${endpoint.crm.merchant.user}?page=${pageNum}&timestamp=${getDateISO()}&role_code=${userRole?.value}`);
            setEntityUsers(isLoadMore ? entityUsers?.concat(res?.list) : res?.list);
            if (res?.list?.length < 6) setShouldLoadMore(false);
        }
        catch (error) {}
    }

    const deleteMerchantUser = async () =>
    {
        try
        {
            const res = await request("DELETE", `${endpoint.crm.merchant.user}?user_id=${removedUserId}`);
            setRemovedUserId(null);
        }
        catch (error) {}
    }

    const updateMerchantUser = async () =>
    {
        try
        {
            const res = await request("PUT", `${endpoint.crm.merchant.user}?user_id=${updatedRole?.user_id}&role_code=${updatedRole?.role?.value}`);
            setUpdatedRole({});
        }
        catch (error) {}
    }

    const fetchPricingData = async () =>
    {
        try
        {
            const res = await request("GET", endpoint.cms.pricing.data);
            const
            {
                basic_fee,
                essentials_fee,
                premium_fee,
                enterprise_fee
            } = res?.info || {}
            setPricingData([
                {
                    key: "basic",
                    label: <span className="flex items-center gap-grid-xxs">{strings("subscriptionPricing.basic")} (<PriceToPriceWithCurrency currency={prefs?.currency} price={basic_fee} />)</span>,
                    price: basic_fee
                },
                {
                    key: "essentials",
                    label: <span className="flex items-center gap-grid-xxs">{strings("subscriptionPricing.essentials")} (<PriceToPriceWithCurrency currency={prefs?.currency} price={essentials_fee} />)</span>,
                    price: essentials_fee
                },
                {
                    key: "premium",
                    label: <span className="flex items-center gap-grid-xxs">{strings("subscriptionPricing.premium")} (<PriceToPriceWithCurrency currency={prefs?.currency} price={premium_fee} />)</span>,
                    price: premium_fee
                },
                {
                    key: "enterprise",
                    label: <span className="flex items-center gap-grid-xxs">{strings("subscriptionPricing.enterprise")} (<PriceToPriceWithCurrency currency={prefs?.currency} price={enterprise_fee} />)</span>,
                    price: enterprise_fee
                }
            ]);
        }
        catch (error) {}
    };

    const updateMerchantSubscription = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("PUT", `${endpoint.crm.merchant.subscription}?subscription_code=${selectedSubscription?.key}&renewal_term=${12}`);
            setIsLoading(false);
        }
        catch (error)
        {
            showNotification("error", strings("message.error"), error?.error_msg, prefs?.dir);
            setIsLoading(false);
        }
    }

    useEffect(() =>
    {
        if (pricingData?.length > 0)
        {
            setSelectedSubscription(pricingData?.filter(item => item?.key === merchantInfo?.subscription?.subscription_code)?.[0]?.key ? pricingData?.filter(item => item?.key === merchantInfo?.subscription?.subscription_code)?.[0] : {});
        }
    }, [pricingData]);

    useEffect(() =>
    {
        if (pageNum !== 1)
        {
            fetchMerchantUser(true);
        }
    }, [pageNum]);

    useEffect(() =>
    {
        if (removedUserId !== null)
        {
            deleteMerchantUser();
        }
    }, [removedUserId]);

    useEffect(() =>
    {
        if (Object.keys(updatedRole)?.length !== 0)
        {
            updateMerchantUser();
        }
    }, [updatedRole]);

    useEffect(() =>
    {
        setEntityUsers([]);
        setUpdatedRole({});
        setRemovedUserId(null);
        setPageNum(1);
        setShouldLoadMore(true);
        if (active === "users") fetchMerchantUser();
    }, [userRole]);

    useEffect(() =>
    {
        setEntityUsers([]);
        setEntityName(merchantInfo?.display_name ? merchantInfo?.display_name : null);
        setCRNumber(merchantInfo?.cr_number ? merchantInfo?.cr_number : null);
        setCRCountryCode(merchantInfo?.country_code ? merchantInfo?.country_code : null);
        setSelectedCountry(CR_ISSUED_COUNTRY?.filter(item => item.country_code === merchantInfo?.country_code)?.length > 0 ? CR_ISSUED_COUNTRY?.filter(item => item.country_code === merchantInfo?.country_code)?.[0] : {});
        setCRExpiryDate(merchantInfo?.cr_end_date ? merchantInfo?.cr_end_date : null);
        setCRFile({});
        setUserRole(constant.user_role[0]);
        setUpdatedRole({});
        setRemovedUserId(null);
        setPageNum(1);
        setShouldLoadMore(true);
        setSelectedSubscription(pricingData?.filter(item => item?.key === merchantInfo?.subscription?.subscription_code)?.[0]?.key ? pricingData?.filter(item => item?.key === merchantInfo?.subscription?.subscription_code)?.[0] : {});
        if (active === "users") fetchMerchantUser();
    }, [active]);

    useEffect(() =>
    {
        fetchPricingData();
        return () => {
            setModalOptions({});
        }
    }, [])

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}
                list={[
                    { key: "details", label: strings("common.details") },
                    { key: "legalEntity", label: strings("common.legalEntity") },
                    { key: "users", label: strings("common.users") },
                    { key: "subscriptions", label: strings("common.subscriptionsAndPayment") },
                    { key: "integration", label: strings("common.integration") }
                ]}
                shouldShowAll={false}
            >
                <div className="p-grid-md text-neutral-low-darkest font-secondary">
                    {active === "details"
                        ? <ManageEntityDetails entityName={entityName} setEntityName={setEntityName} />
                        : active === "legalEntity"
                        ? <ManageEntityLegal
                            CR_ISSUED_COUNTRY={CR_ISSUED_COUNTRY}
                            crNumber={crNumber}
                            selectedCountry={selectedCountry}
                            crExpiryDate={crExpiryDate}
                            crFile={crFile}
                            setCRNumber={setCRNumber}
                            setSelectedCRCountryCode={setSelectedCRCountryCode}
                            setCRExpiryDate={setCRExpiryDate}
                            setCRFile={setCRFile}
                        />
                        : active === "users"
                        ? <ManageEntityUsers
                            entityUsers={entityUsers}
                            userRole={userRole}
                            shouldLoadMore={shouldLoadMore}
                            updatedRole={updatedRole}
                            removedUserId={removedUserId}
                            setRemovedUserId={setRemovedUserId}
                            setUpdatedRole={setUpdatedRole}
                            setEntityUsers={setEntityUsers}
                            setUserRole={setUserRole}
                            loadMore={loadMore}
                        />
                        : active === "subscriptions"
                        ? <ManageEntitySubscriptions pricingData={pricingData} selectedSubscription={selectedSubscription} setSelectedSubscription={setSelectedSubscription} />
                        : <ManageEntityIntegration />
                    }
                </div>
            </Tabs>
            {active !== "integration" &&
                <div className={`flex ${active === "users" ? "justify-start" : "justify-end"} pt-grid-lg mx-grid-md pb-grid-md`}>
                    {active === "users" && roleCode === "owner" &&
                        <Button
                            disabled={false}
                            text={strings("button.inviteUsers")}
                            bgColor={"bg-transparent"}
                            hoverBGColor={"hover:bg-neutral-low-darkest"}
                            textColor={"text-neutral-low-darkest"}
                            hoverTextColor={"hover:text-neutral-high-lightest"}
                            hoverBorder={"hover:border-2 hover:border-transparent"}
                            border={"border-2 border-neutral-low-darkest"}
                            padding="py-2 px-10"
                            onClick={() => {
                                setActiveModal("inviteEntityUsers");
                                setModalOptions({
                                    title: strings("title.inviteUsers")
                                });
                            }}
                        />
                    }
                    <Button
                        disabled={!isValidForm || isLoading || selectedSubscription?.key === merchantInfo?.subscription?.subscription_code}
                        text={active === "subscriptions" ? <span className="flex items-center gap-grid-xxs">{strings("button.pay")} {<PriceToPriceWithCurrency currency={prefs?.currency} price={selectedSubscription?.price ? selectedSubscription?.price : 0} customIconFill="fill-neutral-high-lightest"/>}</span> : strings("button.save")}
                        padding="py-2 px-grid-xl"
                        optionalStyle={active === "users" ? "hidden" : ""}
                        onClick={active === "subscriptions" ? updateMerchantSubscription : save}
                    />
                </div>
            }
        </>
    );
};

export default ManageEntity;