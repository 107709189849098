import i18n from "./i18n.js";
import dayjs from "dayjs";
import "dayjs/locale/ar.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SVGIcon from "../view/component/svgIcons.js";

export const strings = (path) => i18n.t(path);

export const secToMinSec = (s) =>
{
    return `${~~((s % 3600) / 60) > 9 ? "" : "0"}${(s - (s %= 60)) / 60 + (s > 9 ? ":" : ":0") + s}`;
};

export const handleSEOURL = (path, lang, country) =>
{
    const pathnameList = path?.split("/");
    pathnameList[1] = `${lang}-${country}`;
    const url = pathnameList?.join("/");
    return url;
};

export const getLangCountryURL = (lang, country) =>
{
    return `${lang}-${country}`;
};

export const getDate = (lang, date = undefined, format = "DD MMMM YYYY", customFormat = "HH:mm:ss", isCustom = false) =>
{
    dayjs.locale(lang);
    dayjs.extend(customParseFormat);
    return isCustom ? dayjs(date, customFormat).format(format) : dayjs(date).format(format);
};

export const getDateISO = (date = undefined) =>
{
    return date ? dayjs(date).toISOString() : dayjs().toISOString();
};

export const getPreviousTuesday = () => {
    const today = dayjs();
    const dayOfWeek = today.day();

    if (dayOfWeek === 2) {
        return today.format("YYYY-MM-DD");
    } else {
        const daysToSubtract = (dayOfWeek - 2 + 7) % 7 || 7;
        return today.subtract(daysToSubtract, "day").format("YYYY-MM-DD");
    }
};

export const generateRandomMonochromaticArray = (startColor, endColor, arrayLength) =>
{
    const colorsArray = [];

    // Convert the starting and ending colors to RGB format
    const startInt = parseInt(startColor.replace(/^#/, ""), 16);
    const endInt = parseInt(endColor.replace(/^#/, ""), 16);

    // Extract the red, green, and blue values for both colors
    const startR = (startInt >> 16) & 255;
    const startG = (startInt >> 8) & 255;
    const startB = startInt & 255;

    const endR = (endInt >> 16) & 255;
    const endG = (endInt >> 8) & 255;
    const endB = endInt & 255;

    for (let i = 0; i < arrayLength; i++)
    {
        // Calculate new RGB values with a fixed step size
        let newR = startR + ((endR - startR) / (arrayLength - 1)) * i;
        let newG = startG + ((endG - startG) / (arrayLength - 1)) * i;
        let newB = startB + ((endB - startB) / (arrayLength - 1)) * i;

        // Ensure values are within valid range (0 to 255)
        newR = Math.min(255, Math.max(0, Math.round(newR)));
        newG = Math.min(255, Math.max(0, Math.round(newG)));
        newB = Math.min(255, Math.max(0, Math.round(newB)));

        // Convert the new RGB values back to hexadecimal
        const newColor = `#${(1 << 24 | newR << 16 | newG << 8 | newB).toString(16).slice(1)}`;

        colorsArray.push(newColor);
    }

    return colorsArray;
};

export const numberToNumberWithCommas = (number) =>
{
    if (number !== undefined && number !== null)
    {
        return parseFloat(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else
    {
        return "";
    }
};

export const numberToNumberWithCommasAndDicimals = (number) =>
{
    if (number !== undefined && number !== null)
    {
        return parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else
    {
        return "";
    }
};

export const truncateString = (str, maxLength) =>
{
    if (str?.length > maxLength) {
        return `${str?.slice(0, maxLength)}...`;
    }

    return str;
}