import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Section from "../view/component/section";
import ChartContestantLarge from "../view/component/chartContestantLarge";
import ChartContestantMedium from "../view/component/chartContestantMedium";
import DateRange from "../view/component/dateRange";
import DropdownMenu from "../view/component/dropdownMenu";
import Loader from "../view/component/loader";

import { SessionContext } from "../util/context";
import { strings, getLangCountryURL, getDate, getDateISO } from "../util/formatter";
import { request } from "../util/http";
import endpoint from "../config/endpoint";
import constant from "../config/constant";

const TYPE = [
    {
        title: "common.products",
        key: "product"
    },
    {
        title: "common.contributors",
        key: "contributor"
    },
    {
        title: "common.producers",
        key: "producer"
    }
];

const Charts = () =>
{
    const { prefs } = useContext(SessionContext);
    const params = useParams();
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(1)
    const [activeTab, setActiveTab] = useState(params?.type ? params?.type : "products");
    const [selectedChart, setSelectedChart] = useState(TYPE?.filter(item => item.key === activeTab)?.[0]);
    const [activeCategory, setActiveCategory] = useState(params?.category ? params?.category : "");
    const [category, setCategory] = useState(constant?.categories?.filter(item => item.key === activeCategory)?.[0]);
    const [chartInfo, setChartInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const setFirstParam = (type) =>
    {
        if (type?.key !== activeTab)
        {
            setActiveTab(type?.key);
            setSelectedChart(type);
            setActiveCategory("");
            if (type?.key === "product")
            {
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${type?.key}/${activeCategory === "all" ? "" : activeCategory}`);
            }
            else
            {
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${type?.key}`);
            }
        }
    };

    const setSecondParam = (categoryObj) =>
    {
        if (categoryObj?.key !== activeCategory)
        {
            setActiveCategory(categoryObj?.key === "all" ? "" : categoryObj?.key);
            setCategory(categoryObj);
            navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${activeTab}/${categoryObj?.key === "all" ? "" : categoryObj?.key}`);
        }
    };

    const fetchChartData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.leaderboard.list}?page=${pageNumber}&type_code=${activeTab === "product" ? "global_identifier" : activeTab}&period_code=week&period=${getDate(prefs?.lang, undefined, "YYYY-MM-DD")}&timestamp=${getDateISO()}&group_code=qty`);
            setChartInfo(res?.info);
            setIsLoading(false);
        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/product` ||
        location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/product/`)
        {
            setActiveTab(TYPE?.[0]);
            setSelectedChart(TYPE?.[0]);
            setActiveCategory("");
            setCategory({});
        }
    }, [location.pathname]);

    useEffect(() =>
    {
        setActiveTab(params?.type);
        fetchChartData();
    }, [params?.type, params?.category]);

    return (
        <Section optionalStyle="min-h-[calc(100vh-474px)]">
            {isLoading && <Loader />}
            <Section optionalStyle="min-h-auto">
                <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%] pb-grid-xl px-grid-md md:px-0">
                    <div className="flex items-center justify-between gap-grid-xs text-primary-lightest">
                        <div>
                            <p className="py-grid-sm text-xl font-primary text-primary-lightest">
                                {strings("chartPage.mostHundredPaid").replace("{chart}", strings(`common.${activeTab}`))}
                            </p>
                            <DateRange dateFrom={getDate(prefs?.lang, chartInfo?.start_date, "YYYY-MM-DD")} dateTo={getDate(prefs?.lang, chartInfo?.end_date, "YYYY-MM-DD")} />
                        </div>
                        <div className="flex items-center gap-grid-xs">
                            <DropdownMenu
                                useLocalization={true}
                                list={TYPE}
                                labelKey="title"
                                placeholder={strings("placeholder.filterType")}
                                dropdownBG="bg-secondary-lightest"
                                selectedValue={selectedChart}
                                setSelected={setFirstParam}
                            />
                            {activeTab === "product" &&
                                <DropdownMenu
                                    useLocalization={true}
                                    list={constant?.categories}
                                    selectedValue=
                                    {
                                        category?.key === undefined
                                        ? {
                                            title: "common.allBooks",
                                            key: "all"
                                        }
                                        : category
                                    }
                                    labelKey="title"
                                    placeholder={strings("placeholder.category")}
                                    // bgColor="bg-accent-regular"
                                    // textColor="text-accent-lightest"
                                    // iconStroke="stroke-accent-lightest"
                                    // font="text-md font-secondary"
                                    // roundedStyle="rounded-full"
                                    // optionalStyle="border-2 border-transparent"
                                    // padding="py-2 px-4"
                                    // width="w-[120px]"
                                    // gap="gap-grid-xs"
                                    dropdownBG="bg-secondary-lightest"
                                    setSelected={setSecondParam}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Section>
            <Section>
                <div className="relative pb-grid-xl px-grid-md md:px-0">
                    <div className="hidden xl:flex flex-wrap xl:flex-nowrap gap-grid-xxl pb-grid-xxl">
                        {chartInfo?.list?.slice(0, 3)?.sort((a, b) => a.position_current - b.position_current)?.map((item, index) => (
                            <ChartContestantLarge
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}

                            />
                        ))}
                    </div>
                    <div className="flex xl:hidden flex-col gap-grid-sm pt-grid-md pb-grid-md">
                        {chartInfo?.list?.slice(0, 3)?.sort((a, b) => a.position_current - b.position_current)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col gap-grid-sm">
                        {chartInfo?.list?.slice(3, chartInfo?.list?.length)?.sort((a, b) => a.position_current - b.position_current)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))}
                    </div>
                </div>
            </Section>
        </Section>
    );
};

export default Charts;