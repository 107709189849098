import React, { useContext } from "react"

import SVGIcon from "./svgIcons"

import { strings } from "../../util/formatter"
import { SessionContext } from "../../util/context"

const PriceToPriceWithCurrency = (props) =>
{
    const { isPostLogin } = useContext(SessionContext)
    const {
        currency,
        price,
        customStyle = "",
        customIconFill = `${isPostLogin ? "fill-neutral-low-dark" : "fill-secondary-darkest"}`
    } = props

    if (currency === "sar")
    {
        return (
            <span className={`flex items-center gap-grid-xxs ${customStyle}`}>{price} <SVGIcon name="saudiRiyal" viewBox="0 0 1124.14 1256.39" width={16} height={16} className={customIconFill}/></span>
        )
    }
    else
    {
        return strings(`currency.${currency}`)?.replace("{PRICE}", price)
    }
}

export default PriceToPriceWithCurrency;