import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Section from "../view/component/section";
import RankRow from "../view/component/rankRow";
import CachedImg from "../view/component/cachedImg";
import Tabs from "../view/component/tabs";
import DropdownMenu from "../view/component/dropdownMenu";
import SVGIcon from "../view/component/svgIcons";
import Loader from "../view/component/loader";
import PriceToPriceWithCurrency from "../view/component/priceToPriceWithCurrency";

import constant from "../config/constant";
import endpoint from "../config/endpoint";
import { SessionContext } from "../util/context";
import { getDate, getLangCountryURL, strings } from "../util/formatter";
import { request } from "../util/http";

const Product = () =>
{
    const { prefs } = useContext(SessionContext);
    const [active, setActive] = useState("branches");
    const ITEM = {
        id: 1,
        cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
        name: "اعرف لماذا يغرد الطائر الحبيس",
        des: "بعد علاقة مضطربة، قام الزوجان (بيلي سينيور) و (فيفيان باكستر) بشحن ابنائهما (مايا) و(بيلي جونيور) إلى الجدة في جنوب الولايات المتحدة، تماماً مثلما تُشحن البضائع، ليكتشف الطفلان بعدها أن الحياة في الجنوب لا يمكن أن تكون سوى صورة حقيقة للجحيم، إذ وبخلاف حالة الطقس المتردية، كانت بلدة الجدّة واقعة تحت تأثير الفصل العنصري وما تبقى من أعراف اضطهاد السود، وكما لو أن هذا لم يكن كافياً، جاءت حالة الكساد الكبير لتدفع ذاك المجتمع الجنوبي، وتدفع الطفلين أيضاً، إلى دائرة الهلاك، مما زاد من عدد الحواجز التي توجّب عليهم أن يتجاوزوها.",
        isbn: "798-098-09876",
        rank: {
            place: 5,
            chart: "product"
        },
        contributors: [
            {
                name: "إياد عبدالرحمن (مترجم)",
                id: "Eyad_Abdulrahman"
            },
            {
                name: "مايا آنجلو (مؤلف)",
                id: "Maya_Angelo"
            }
        ],
        producers: [
            {
                name: "دار ميلاد للنشر والتوزيع",
                id: "Melad_Publishing_and_Distribution_House"
            }
        ],
        produced_at: "2023-09-24Z00:00:00",
        languages: [
            "العربية",
            "الانجليزية"
        ],
        pages_count: 382,
        categories: [
            "رواية",
            "رحلات",
            "تاريخ"
        ],
        types: [
            "ورقي"
        ],
        price: "54.99 ريال سعودي",
        publishing_status: "متوفر",
        distribution_status: "متوفر",
        audience_group: [
            "بالغين",
            "أطفال"
        ],
        availability: {
            branches: [
                {
                    id: 1,
                    name: "الرياض",
                    list: [
                        {
                            id: 1,
                            name: "مكتبة جرير",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        },
                        {
                            id: 2,
                            name: "فيرجن ستور",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        }
                    ]
                }
            ],
            online_stores: [
                {
                    id: 1,
                    name: "دار ميلاد للنشر و التوزيع",
                    link: "https://store.darmelad.com"
                },
                {
                    id: 2,
                    name: "مكتبة جرير",
                    link: "https://www.jarir.com/arabic-books-472235.html"
                }
            ]
        },
        related: [
            {
                id: 1,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 5,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            }
        ]
    };
    const [isLoading, setIsLoading] = useState(false);
    const [productData, setProductData] = useState({});
    const [selected, setSelected] = useState(ITEM.availability.branches[0]);
    const list = [
        { key: "branches", label: strings("productPage.retailers") },
        { key: "online_stores", label: strings("productPage.onlineStores") }
    ];

    const BasicContentList = (title, list, isLink = false, id = "", path = "") =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <div className="flex flex-col gap-grid-xs">
                    {list?.length > 0 && list?.map((item, index) => (
                        isLink
                            ? <Link key={index} to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${path}/${item?.[id]}`} className="underline underline-offset-2 text-primary-regular">
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name_ar : list[index]?.name_en}
                            </Link>
                            : <p key={index}>
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name_ar : list[index]?.name_en}
                            </p>
                    ))}
                </div>
            </div>
        );
    };

    const BasicContent = (title, item) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <p>
                    {item}
                </p>
            </div>
        );
    };

    const ContentWithBG = (title, data, isList = true) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {isList
                    ? data?.length > 0 && data?.map((item, index) => (
                            <p key={index} className="px-grid-md py-grid-xxs bg-primary-light text-secondary-darkest text-center rounded-xl">
                                {prefs?.lang === constant.lang.values[0] ? data[index]?.name_ar : data[index]?.name_en}
                            </p>
                        ))
                    : <p className="px-grid-md py-grid-xxs bg-primary-light text-secondary-darkest text-center rounded-xl">
                            {data}
                        </p>
                    }
                </div>
            </div>
        );
    };

    const fetchProductData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.data}?product_id=673f667244f5ba832cf17e60`);
            setProductData(res?.info);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() =>
    {
        fetchProductData();
    }, [])

    return (
        <Section useContainer={true}>
            {isLoading && <Loader />}
            <div className="relative mx-grid-md md:mx-0 pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%] text-base">
                <p className="px-grid-xl md:px-0 container mx-auto pb-grid-lg text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? productData?.name_ar : productData?.name_en}
                </p>
                <div className="px-grid-xl md:px-0 container mx-auto pb-grid-xl">
                    <RankRow rank={ITEM.rank?.place} path={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${ITEM.rank?.chart}/all`}/>
                </div>
                <div className="mx-grid-xl md:mx-auto container flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-between p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-full md:w-[25%]">
                        <CachedImg src={productData?.thumbnail_url} alt={prefs?.lang === constant.lang.values[0] ? productData?.seo_slug_ar : productData?.seo_slug_en} className="w-full rounded-sm"/>
                    </div>
                    <div className="w-full md:w-[75%] font-secondary">
                        <p className="leading-normal">
                            {prefs?.lang === constant.lang.values[0] ? productData?.description_ar : productData?.description_en}
                        </p>
                        {BasicContentList("common.theContributor", productData?.contributor, true, "id", "contributor")}
                        {BasicContentList("common.theProducer", productData?.producer, true, "id", "producer")}
                        {BasicContent("common.producedAt", getDate(prefs?.lang, productData?.produced_at))}
                        {BasicContent("common.isbn", productData?.global_identifier)}
                        {ContentWithBG("common.languages", productData.lang_code === "ar" ? strings("language.ar") : strings("language.en"), false)}
                        {BasicContent("common.pagesCount", productData?.pages_count)}
                        {ContentWithBG("common.category", productData?.subject)}
                        {ContentWithBG("common.type", prefs?.lang === constant.lang.values[0] ? productData?.form?.name_ar : productData?.form?.name_en, false)}
                        {BasicContent("common.price", <PriceToPriceWithCurrency currency={prefs?.currency} price={productData?.price} />)}
                        {BasicContent("productPage.publishingStatus", prefs?.lang === constant.lang.values[0] ? productData?.publishing_status?.name_ar : productData?.publishing_status?.name_en)}
                        {BasicContent("productPage.distributionStatus", prefs?.lang === constant.lang.values[0] ? productData?.distribution_status?.name_ar : productData?.distribution_status?.name_en)}
                        {ContentWithBG("productPage.audienceGroup", productData?.audience)}
                    </div>
                </div>
                <div className="mx-grid-xl md:mx-auto container mt-grid-xxl mb-grid-xxl">
                    <p className="py-grid-xxl text-xl font-primary text-primary-lightest">
                        {strings(`productPage.salePoints`)}
                    </p>
                    <Tabs active={active} setActive={setActive} list={list}>
                        {active === "branches" &&
                            <>
                                <div className="mx-grid-md md:mx-0 p-grid-lg flex justify-end">
                                    <DropdownMenu
                                        list={ITEM.availability.branches}
                                        labelKey="name"
                                        selectedValue={selected}
                                        setSelected={setSelected}
                                    />
                                </div>
                                <div className="divide-y divide-solid divide-primary-light px-grid-lg pb-grid-lg text-base">
                                    {ITEM.availability?.branches?.filter(branch => branch?.id === selected?.id)?.[0]?.list?.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/seller/${item?.id}`}
                                            className="p-grid-sm flex gap-grid-sm items-center justify-between text-secondary-darkest cursor-pointer"
                                        >
                                            <div className="flex gap-grid-xs items-center">
                                                {item?.name}
                                            </div>
                                            <div className="flex items-center">
                                                <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </>
                        }
                        {active === "online_stores" &&
                            <div className="divide-y divide-solid divide-primary-light p-grid-lg">
                                {ITEM.availability?.online_stores?.map((item, index) => (
                                    <Link
                                        key={index}
                                        to={item?.link}
                                        target="_blank"
                                        className="p-grid-sm flex gap-grid-sm items-center justify-between text-secondary-darkest cursor-pointer"
                                    >
                                        <div className="flex gap-grid-xs items-center">
                                            {item?.name}
                                        </div>
                                        <div className="flex items-center">
                                            <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        }
                    </Tabs>
                </div>
            </div>
        </Section>
    );
};

export default Product;