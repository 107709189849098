import React, { createRef, useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Label from "../view/component/label";
import Button from "../view/component/button";
import FileInput from "../view/component/fileInput";

import endpoint from "../config/endpoint";
import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import { request } from "../util/http";
import { showNotification } from "../util/notification";
import { validateFileType } from "../util/validator";

export const FILE_TYPE = [
    {
        name: "اكسل (xls أو xlsx)",
        value: "excel"
    }
];

const UploadBulkOfProductsModal = () =>
{
    const navigate = useNavigate();
    const { prefs, setActiveModal } = useContext(SessionContext);
    const productFileRef = createRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(FILE_TYPE[0]);
    const [productFile, setProductFile] = useState({});

    const selectProductFile = () =>
    {
        if (productFileRef.current !== null)
        {
            productFileRef.current.click();
        }
    };

    const downloadSample = () =>
    {
        const sampleUrl = "https://cdn.mofahres.com/sample/sample_upload.xlsx";
        if (window)
        {
            window.open(sampleUrl, "_blank");
        }
    }

    const upload = async () =>
    {
        if (productFile?.name)
            {
                setIsLoading(true);
                try
                {
                    await request("POST", endpoint.bms.product.data, { post_format: 'file', post_file: productFile });
                    setIsLoading(false);
                    setActiveModal(undefined);
                    setTimeout(() =>
                    {
                        navigate(0);
                    }, 1500);
                }
                catch (error)
                {
                    setIsLoading(false);
                    showNotification("error", strings("message.error"), error?.error_msg, prefs?.dir);
                }
            };
    };

    return (
        <div className="p-grid-md">
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.selectFileExtention")}/>
                <DropdownMenu
                    list={FILE_TYPE}
                    labelKey="name"
                    placeholder={strings("placeholder.selectFileExtention")}
                    selectedValue={type}
                    setSelected={setType}
                />
                <LineMsg type="error"/>
            </div>

            <div className="pb-grid-sm">
                <Label label={strings("label.productsFile")}/>
                <FileInput
                    fileRef={productFileRef}
                    value={productFile}
                    setValue={setProductFile}
                    selectFile={selectProductFile}
                    acceptedTypes=".xls,.xlsx"
                />
                <LineMsg msg={validateFileType(productFile, ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"], ["xls", "xlsx"])?.msg} type="error"/>
            </div>

            <div className="flex justify-between text-end pt-grid-lg">
                <Button
                    text={strings("button.downloadXLSSample")}
                    padding="py-2 px-grid-xl"
                    onClick={downloadSample}
                />
                <Button
                    disabled={isLoading || !productFile?.name}
                    text={strings("button.upload")}
                    padding="py-2 px-grid-xl"
                    onClick={upload}
                />
            </div>
        </div>
    );
};

export default UploadBulkOfProductsModal;