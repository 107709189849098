import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import CachedImg from "../view/component/cachedImg";
import Tabs from "../view/component/tabs";
import ListOfSales from "../view/component/listOfSales";
import PriceToPriceWithCurrency from "../view/component/priceToPriceWithCurrency";

import { SessionContext } from "../util/context";
import { getDate, getLangCountryURL, strings } from "../util/formatter";
import constant from "../config/constant";
import { request } from "../util/http";
import endpoint from "../config/endpoint";

export const countries =
[
    {
        name: "السعودية",
        value: "saudi"
    },
    {
        name: "الامارات",
        value: "emirates"
    }
];

export const ITEM = {
    id: 1,
    cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
    name: "اعرف لماذا يغرد الطائر الحبيس",
    des: "بعد علاقة مضطربة، قام الزوجان (بيلي سينيور) و (فيفيان باكستر) بشحن ابنائهما (مايا) و(بيلي جونيور) إلى الجدة في جنوب الولايات المتحدة، تماماً مثلما تُشحن البضائع، ليكتشف الطفلان بعدها أن الحياة في الجنوب لا يمكن أن تكون سوى صورة حقيقة للجحيم، إذ وبخلاف حالة الطقس المتردية، كانت بلدة الجدّة واقعة تحت تأثير الفصل العنصري وما تبقى من أعراف اضطهاد السود، وكما لو أن هذا لم يكن كافياً، جاءت حالة الكساد الكبير لتدفع ذاك المجتمع الجنوبي، وتدفع الطفلين أيضاً، إلى دائرة الهلاك، مما زاد من عدد الحواجز التي توجّب عليهم أن يتجاوزوها.",
    isbn: "798-098-09876",
    rank: {
        place: 5,
        chart: "product"
    },
    contributors: [
        {
            name: "إياد عبدالرحمن (مترجم)",
            id: "Eyad_Abdulrahman"
        },
        {
            name: "مايا آنجلو (مؤلف)",
            id: "Maya_Angelo"
        }
    ],
    producers: [
        {
            name: "دار ميلاد للنشر والتوزيع",
            id: "Melad_Publishing_and_Distribution_House"
        }
    ],
    produced_at: "2023-09-24Z00:00:00",
    languages: [
        "العربية",
        "الانجليزية"
    ],
    pages_count: 382,
    categories: [
        "رواية",
        "رحلات",
        "تاريخ"
    ],
    types: [
        "ورقي"
    ],
    price: "54.99 ريال سعودي",
    publishing_status: "متوفر",
    distribution_status: "متوفر",
    audience_group: [
        "بالغين",
        "أطفال"
    ],
    product_summary: "ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب ملخص الكتاب",
    current_position: [
        {
            position: "المركز 8 أفضل الروايات"
        },
        {
            position: "المركز 4 أفضل الكتب العربية"
        }
    ],
    sales_by_distributor: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "مكتبة جرير",
                sales: 55
            },
            {
                id: 2,
                name: "فيرجن ستور",
                sales: 55
            },
            {
                id: 3,
                name: "المكتبة التراثية",
                sales: 55
            }
        ]
    },
    sales_by_region: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "الرياض",
                sales: 55
            },
            {
                id: 2,
                name: "جدة",
                sales: 55
            },
            {
                id: 3,
                name: "الدمام",
                sales: 55
            }
        ]
    },
    sales_by_day: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "الرياض",
                sales: 55
            },
            {
                id: 2,
                name: "جدة",
                sales: 55
            },
            {
                id: 3,
                name: "الدمام",
                sales: 55
            }
        ]
    }
};
export const ITEM_EN = {
    id: 1,
    cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
    name: "Know why the caged bird chirps",
    des: "After a troubled relationship, the couple (Billy Senior) and (Vivienne Baxter) shipped their sons (Maya) and (Billy Jr.) to the grandmother in the southern United States, just as goods are shipped, and the children then discover that life in the South can only be a true picture of hell, as unlike the bad weather, the town of Grandmother was under the influence of apartheid and the remaining customs of black oppression, and as if this were not enough, the Great Depression came to push that Southern society, It also pushes the children into doom, increasing the number of barriers they have had to overcome. ",
    isbn: "798-098-09876",
    rank: {
        place: 5,
        chart: "product"
    },
    contributors: [
        {
            name: "Eyad Abdulrahman (translator)",
            id: "Eyad_Abdulrahman"
        },
        {
            name: "Maya Angelo (author)",
            id: "Maya_Angelo"
        }
    ],
    producers: [
        {
            name: "Melad Publishing and Distribution House",
            id: "Melad_Publishing_and_Distribution_House"
        }
    ],
    produced_at: "2023-09-24Z00:00:00",
    languages: [
        "Arabic",
        "English"
    ],
    pages_count: 382,
    categories: [
        "novel",
        "trips",
        "history"
    ],
    types: [
        "Paper"
    ],
    price: "54.99 SAR",
    publishing_status: "Available",
    distribution_status: "Available",
    audience_group: [
        "Adults",
        "Kids"
    ],
    product_summary: "Book summary Book summary Book summary Book summary Book summary Book summary Book summary Book summary Book summary Book summary Book summary",
    current_position: [
        {
            position: "Top 8 in Novel"
        },
        {
            position: "Top 4 in Arabic Books"
        }
    ],
    sales_by_distributor: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "Jarir Bookstore",
                sales: 55
            },
            {
                id: 2,
                name: "Vergin Store",
                sales: 55
            },
            {
                id: 3,
                name: "Torathyah Library",
                sales: 55
            }
        ]
    },
    sales_by_region: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "Riyadh",
                sales: 55
            },
            {
                id: 2,
                name: "Jeddah",
                sales: 55
            },
            {
                id: 3,
                name: "Dammam",
                sales: 55
            }
        ]
    }
};

const ProductModal = () =>
{
    const { prefs, isPostLogin } = useContext(SessionContext);
    const [active, setActive] = useState("details");
    const [selected, setSelected] = useState(countries[0]);
    const [date, setDate] = useState(Date.now());
    const [productInfo, setProductInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const BasicContentList = (title, list, listEn, key, isLink = false, id = "", path = "") =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[130px] font-primary">
                    {strings(title)}:
                </p>
                <div className="flex flex-col gap-grid-xs">
                    {list.map((item, index) => (
                        isLink
                            ? <Link key={index} to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${path}/${item?.[id]}`} className="underline underline-offset-2 text-primary-regular">
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.[key] : listEn[index]?.[key]}
                            </Link>
                            : <p key={index}>
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.[key] : listEn[index]?.[key]}
                            </p>
                    ))}
                </div>
            </div>
        );
    };

    const BasicContent = (title, item, itemEn) =>
    {
        return (
            <div className="grid grid-flow-col grid-cols-[max-content] gap-grid-xs pt-grid-sm">
                <p className="w-[130px] font-primary">
                    {strings(title)}:
                </p>
                <div>
                    {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                </div>
            </div>
        );
    };

    const ContentWithBG = (title, list, listEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[130px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {list.map((item, index) => (
                        <p key={index} className={`px-grid-md py-grid-xxs ${isPostLogin ? "bg-neutral-high-regular text-neutral-low-darkest" : "bg-primary-light text-secondary-darkest"} text-center rounded-xl`}>
                            {prefs?.lang === constant.lang.values[0] ? list[index] : listEn[index]}
                        </p>
                    ))}
                </div>
            </div>
        );
    };

    const fetchProductData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.data}?product_id=673f667244f5ba832cf17e60`);
            setProductInfo(res?.info);
            setIsLoading(false);
        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        fetchProductData();
    }, []);

    return (
        <Tabs
            active={active}
            setActive={setActive}
            isModal={true}
            list={[
                { key: "details", label: strings("common.details") },
                { key: "salesByDistributor", label: strings("common.salesByDistributor") },
                { key: "salesByRegion", label: strings("common.salesByRegion") }
            ]}
            shouldShowAll={false}
        >
            {active === "details"
                ? <div className={`mx-grid-xl md:mx-auto p-grid-md flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-between ${isPostLogin ? "text-neutral-low-darkest" : "text-secondary-darkest"} text-sm font-secondary`}>
                    <div className="w-full md:w-[100px]">
                        <CachedImg src={productInfo?.thumbnail_url} alt className="w-full"/>
                    </div>
                    <div className="w-full md:w-[75%] font-secondary">
                        <p className="text-sm leading-normal">
                            {prefs?.lang === constant.lang.values[0] ? productInfo?.description_ar : productInfo?.description_en}
                        </p>
                        {BasicContentList("common.theContributor", ITEM.contributors, ITEM_EN.contributors, "name", true, "id", "contributor")}
                        {BasicContentList("common.theProducer", ITEM.producers, ITEM_EN.producers, "name", true, "id", "producer")}
                        {BasicContent("common.producedAt", getDate(prefs?.lang, productInfo?.produced_at), getDate(prefs?.lang, productInfo?.produced_at))}
                        {BasicContent("common.isbn", ITEM.isbn, ITEM_EN.isbn)}
                        {ContentWithBG("common.languages", ITEM.languages, ITEM_EN.languages)}
                        {BasicContent("common.pagesCount", productInfo?.pages_count, productInfo?.pages_count)}
                        {ContentWithBG("common.category", ITEM.categories, ITEM_EN.categories)}
                        {ContentWithBG("common.type", ITEM.types, ITEM_EN.types)}
                        {BasicContent("common.price", <PriceToPriceWithCurrency currency={prefs?.currency} price={productInfo?.price} />)}
                        {BasicContent("productPage.publishingStatus", ITEM.publishing_status, ITEM_EN.publishing_status)}
                        {BasicContent("productPage.distributionStatus", ITEM.distribution_status, ITEM_EN.distribution_status)}
                        {ContentWithBG("productPage.audienceGroup", ITEM.audience_group, ITEM_EN.audience_group)}
                    </div>
                </div>
                : active === "salesByDistributor"
                    ? <ListOfSales
                        list={ITEM?.sales_by_distributor?.list}
                        totalSales={ITEM.sales_by_distributor?.total_sales}
                        filterType="none"
                    />
                    : <ListOfSales
                        list={ITEM?.sales_by_region?.list}
                        totalSales={ITEM.sales_by_region?.total_sales}
                        filterType="date"
                        filterValue={date}
                        setFilterValue={setDate}
                    />
            }
        </Tabs>
    );
};

export default ProductModal;