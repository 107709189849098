import React, { useContext } from "react";

import Label from "./label";
import DropdownMenu from "./dropdownMenu";
import LineMsg from "./lineMsg";

import { strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";

const ManageEntitySubscriptions = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { pricingData, selectedSubscription, setSelectedSubscription } = props;

    return (
        <div className="w-full">
            <Label label={strings("label.subscriptionType")}/>
            <DropdownMenu
                isLabelJSX={true}
                list={pricingData}
                labelKey="label"
                placeholder={strings("placeholder.subscriptionType")}
                selectedValue={selectedSubscription}
                setSelected={setSelectedSubscription}
            />
            <LineMsg type="error"/>
        </div>
    );
};

export default ManageEntitySubscriptions;